import React from "react"
import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import { graphql, Link, useStaticQuery } from "gatsby"
import Logo from "../Logo/Logo"
import Img from "gatsby-image"
import locales from "../../../constants/locales"
import { injectIntl, intlShape } from "react-intl"
import Navigation from "./Navigation/navigation"



const Header = ({ intl: { locale }, path }) => {


  const flags = useStaticQuery(graphql`
      query {
          allFile(filter: {relativeDirectory: {eq: "flags"}}) {
              edges {
                  node {
                      absolutePath
                      relativePath
                      relativeDirectory
                      childImageSharp {
                          fixed(width:18) {
                              ...GatsbyImageSharpFixed_withWebp_tracedSVG
                          }
                      }
                  }
              }
          }
      }

  `)


  return (
    <div className="header headerCustom">

      <Navbar expand="lg">
        <Container>
          <Navbar.Brand className="logo" href="/">
            <Logo containerStyle={{paddingTop:"0px"}}/>
          </Navbar.Brand>
          {/*Used For Mobile Navigation*/}
          <Navbar.Toggle aria-controls="gatsby-navbar-nav"/>
          <Navbar.Collapse id="gatsby-navbar-nav" className="float-right">
            <Nav className="ml-auto ">

              <Navigation locale={locale} path={path}/>


            </Nav>
            <Nav className="mr-auto p-t-20">
              <div>
                {Object.keys(locales).map(key => {

                  let imgFlagSrc = null


                  flags.allFile.edges.forEach((flag, index) => {

                    if (flag.node.relativePath === locales[key].flag) {
                      imgFlagSrc = flag.node.childImageSharp.fixed
                    }
                  })

                  return (

                    <Link
                      className={key === locale ? "active p-1" : "p-1"}
                      key={locales[key].locale}
                      to={locales[key].default ? "/" : `/${locales[key].path}`}
                    >
                      {
                        imgFlagSrc ? <Img alt={locales[key].locale + " flag"} fixed={imgFlagSrc}/> : null
                      }

                    </Link>
                  )
                })}
                {/*<LanguageSwitcher/>*/}
                {/*<IntlContextConsumer>*/}
                {/*    {({ languages, language: currentLocale }) =>*/}
                {/*      languages.map(language => (*/}
                {/*        <button*/}
                {/*          key={language}*/}
                {/*          onClick={() => changeLocale(language)}*/}
                {/*          style={{*/}
                {/*              color: currentLocale === language ? `red` : `black`,*/}
                {/*              margin: 10,*/}
                {/*              textDecoration: `underline`,*/}
                {/*              cursor: `pointer`,*/}
                {/*          }}*/}
                {/*        >*/}
                {/*            {languageName[language]}*/}
                {/*        </button>*/}
                {/*      ))*/}
                {/*    }*/}
                {/*</IntlContextConsumer>*/}
              </div>
            </Nav>

          </Navbar.Collapse>

        </Container>
      </Navbar>
    </div>
  )

}


Header.propTypes = { intl: intlShape.isRequired }

export default injectIntl(Header)